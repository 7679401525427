<template>
  <div class="js-common-wrap js-creater-task">
    <div class="js-common-head">
      <div>
        <el-button class="back-btn" @click="$goBack" icon="el-icon-arrow-left" size="small" type="primary" plain></el-button>
        <label class="label">{{type?'修改任务':'创建任务' }}</label>
      </div>
    </div>
    <div class="js-common-content">
      <div class="js-creater-user-content">
        <ul>
          <li>
            <label class="common-tip"><i>*</i>主任务名称</label>
            <el-input v-model="params.name" size="small"></el-input>
          </li>
          <li>
            <label class="common-tip"><i>*</i>请选择事件</label>
            <div>
              <el-select v-model="params.taskEventIds" placeholder="请选择" size="small" clearable multiple>
                <el-option v-for="item in taskEvents" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </div>
          </li>                     
        </ul>
        <div class="btns">
          <el-button @click="handleCancel" size="small">取 消</el-button>
          <el-button type="primary" @click="handleConfirm" size="small">确 定</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: '',
  data() {
    return {
      params:{
        archieveId:'',
        name:'',
        taskEventIds: [],
      },
      from:'',
      number:'',
      seqnum:'',
      to:'',
      type:'',
      uid:'',
      version:'',
      options:'',
      taskEvents:[],
    }
  },
  mounted() {
    this.params = JSON.parse(this.$route.query.options)
    this.type = parseInt(this.$route.query.type)
    this.getEvents()
//    this.reporterChange()
    
  },
  methods: {

    getEvents(){
      this.$axios({
        method: 'get',
        url: `api/csp/task/v1/taskEvent/list`
      }).then((response)=>{
        let data = response.data
        if(!data.status){
          this.taskEvents = data.data
        }else{
          this.$message({
            type: 'error',
            message: data.msg,
            duration: 1000
          })
        }
      })
    },



    handleConfirm(){
      let url = '', method = ''
      if(this.type){
        method = 'put'
        url = `api/csp/task/v1/task/${this.params.id}`
      }else{
        method = 'post'
        url = `api/csp/task/v1/task/`
      }
      this.$axios({
        method: method,
        url: url,
        data:{
          data:{
            archiveId: this.params.archievId,
            name: this.params.name,
            taskEventIds: this.params.taskEventIds,
          }
        }
      }).then((response)=>{
        let data = response.data
        if(!data.status){
          this.$goBack()
        }else{
          this.$message({
            type: 'error',
            message: data.msg,
            duration: 1000
          })
        }
      })
    },
    handleCancel(){
      this.$goBack()
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/createrUser.scss'
</style>
