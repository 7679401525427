var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "js-common-wrap js-creater-task" }, [
    _c("div", { staticClass: "js-common-head" }, [
      _c(
        "div",
        [
          _c("el-button", {
            staticClass: "back-btn",
            attrs: {
              icon: "el-icon-arrow-left",
              size: "small",
              type: "primary",
              plain: "",
            },
            on: { click: _vm.$goBack },
          }),
          _c("label", { staticClass: "label" }, [
            _vm._v(_vm._s(_vm.type ? "修改任务" : "创建任务")),
          ]),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "js-common-content" }, [
      _c("div", { staticClass: "js-creater-user-content" }, [
        _c("ul", [
          _c(
            "li",
            [
              _vm._m(0),
              _c("el-input", {
                attrs: { size: "small" },
                model: {
                  value: _vm.params.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.params, "name", $$v)
                  },
                  expression: "params.name",
                },
              }),
            ],
            1
          ),
          _c("li", [
            _vm._m(1),
            _c(
              "div",
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      placeholder: "请选择",
                      size: "small",
                      clearable: "",
                      multiple: "",
                    },
                    model: {
                      value: _vm.params.taskEventIds,
                      callback: function ($$v) {
                        _vm.$set(_vm.params, "taskEventIds", $$v)
                      },
                      expression: "params.taskEventIds",
                    },
                  },
                  _vm._l(_vm.taskEvents, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "btns" },
          [
            _c(
              "el-button",
              { attrs: { size: "small" }, on: { click: _vm.handleCancel } },
              [_vm._v("取 消")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.handleConfirm },
              },
              [_vm._v("确 定")]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "common-tip" }, [
      _c("i", [_vm._v("*")]),
      _vm._v("主任务名称"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "common-tip" }, [
      _c("i", [_vm._v("*")]),
      _vm._v("请选择事件"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }